.modal2 {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal2 button {
  outline: none;
  cursor: pointer;
  border: 0;
  display: inline-block;
}
.modal2 > section {
  width: 90%;
  max-width: 320px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;

  animation: modal-show 0.3s;
  overflow: hidden;
}
.modal2 > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: white;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;

  color: #363636;
}
.modal2 > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modal2 > section > main {
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #646464;
}
.modal2 > section > footer {
  padding: 12px 16px;
  display: flex;
  justify-content: space-around;
}
.modal2 > section > footer button:hover {
  padding: 6px 12px;
  color: #fff;
  background-color: #edc21e;
  border-radius: 5px;
  font-size: 13px;
}

.modal2 > section > footer button {
  padding: 6px 12px;
  color: #a3a3a3;
  background-color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.modal2.openModal {
  display: flex;
  align-items: center;

  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
